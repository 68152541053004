import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import 'video-react/dist/video-react.css'
import { Player, BigPlayButton } from 'video-react'
import DOMPurify from 'dompurify';

class ProductDetails extends Component {

     constructor(props) {
          super();
     }


     render() {

          let LongTitle = "";
          let LongDescription = "";
          let sanitizedHTMLforLD = "";
          let sanitizedHTMLforUM = "";
          let sanitizedHTMLforPF = "";
          let TotalDuration = "";
          let TotalLecture = "";
          let TotalStudent = "";
          let SkillAll = "";
          let VideoUrl = "";
          let SmallImg = "";
          let DemoRequest = "";

          let ConurseDetailsArray = this.props.courseallData;
          if (ConurseDetailsArray.length === 1) {
               LongTitle = ConurseDetailsArray[0]['long_title'];
               // LongDescription = ConurseDetailsArray[0]['long_description'];
               sanitizedHTMLforLD = DOMPurify.sanitize(ConurseDetailsArray[0]['long_description']);
               sanitizedHTMLforUM = DOMPurify.sanitize(ConurseDetailsArray[0]['user_manual']);
               sanitizedHTMLforPF = DOMPurify.sanitize(ConurseDetailsArray[0]['product_feature']);
               DemoRequest = ConurseDetailsArray[0]['demo_request'];
               TotalDuration = ConurseDetailsArray[0]['total_duration'];
               TotalLecture = ConurseDetailsArray[0]['total_lecture'];
               TotalStudent = ConurseDetailsArray[0]['total_student'];
               SkillAll = ConurseDetailsArray[0]['skill_all'];
               VideoUrl = ConurseDetailsArray[0]['video_url'];
               SmallImg = ConurseDetailsArray[0]['small_img'];
          }





          return (
               <Fragment>
                    <Container className="mt-5">
                         <Row>
                              <Col lg={8} md={6} sm={12}>
                                   <h1 className="coruseDetailsText"> {LongTitle} </h1>
                                   <img className="courseDetaisImg" src={SmallImg} />
                                   <br></br> <br></br>
                                   {/* API থেকে আসা ডেটা), বা আপনি কোনো কপি-পেস্ট করা ডেটা সংরক্ষণ করছেন, তাহলে ডেটা পিউরিফাই করা অপরিহার্য।
                                   কারণ: এ ধরনের ডেটা Cross-Site Scripting (XSS) আক্রমণের ঝুঁকি তৈরি করতে পারে। যদি কোনো ব্যবহারকারী ইচ্ছাকৃতভাবে ক্ষতিকারক স্ক্রিপ্ট বা HTML ট্যাগ ইনপুট করে, 
                                   তাহলে তা আপনার অ্যাপ্লিকেশন বা ব্যবহারকারীর ব্রাউজারে সমস্যার কারণ হতে পারে। 
                                   উদাহরণ:
                                   <script>alert('Hacked!')</script>

                                    এই স্ক্রিপ্ট ইনপুট করা হলে এবং সরাসরি সেভ ও রেন্ডার করা হলে, এটি আপনার অ্যাপকে হ্যাক করতে পারে।*
                                    
                                    যদি আপনি নিজে হাতে ডেটা লিখছেন এবং নিশ্চিত যে এতে কোনো ক্ষতিকারক স্ক্রিপ্ট বা কোড নেই, তবে সাধারণত পিউরিফাই করার প্রয়োজন নেই।

                                    কিন্তু: এমন ডেটা সংরক্ষণ করার সময়ও ভালো প্র্যাকটিস হলো পিউরিফাই করা। কারণ ভবিষ্যতে অন্য উৎস থেকে ডেটা আসতে পারে বা প্রক্রিয়াকরণে সমস্যা হতে পারে।
                                    
                                     */}

                                   {/* <p className="CoruseallDescription" dangerouslySetInnerHTML={{ __html: LongDescription }}></p> */}
                                   <p className="CoruseallDescription" dangerouslySetInnerHTML={{ __html: sanitizedHTMLforLD }}></p>


                              </Col>





                              <Col lg={4} md={6} sm={12}>

                                   <div className="widget_feature">
                                        <h4 class="widget-title text-center">Product Features</h4>
                                        <hr />
                                        <ul className="CoruseallDescription" dangerouslySetInnerHTML={{ __html: sanitizedHTMLforPF }}>

                                        </ul>

                                        <div class="price-wrap text-center">

                                             <a href={DemoRequest} class="btn btn-warning">SEND A DEMO REQUEST</a>
                                        </div>
                                   </div>
                              </Col>

                         </Row>
                    </Container>


                    <br></br><br></br>
                    <Container>
                         <Row>
                              <Col lg={6} md={6} sm={12}>



                                   <div className="widget_feature">
                                        <h1 className="coruseDetailsText">User Manual </h1>
                                        <hr />

                                        <p className="CoruseallDescription" dangerouslySetInnerHTML={{ __html: sanitizedHTMLforUM }}></p>
                                   </div>


                              </Col>


                              <Col lg={6} md={6} sm={12}>

                                   <Player src="https://unani.softenginebd.com/upload/director/1798106673054956.mp4">
                                        <BigPlayButton position="center" />
                                   </Player>
                              </Col>

                         </Row>
                    </Container>











               </Fragment>
          )
     }
}

export default ProductDetails
